"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSetCart = exports.useCart = exports.getUpsellInputFromCart = exports.getUpsellInput = exports.getTicketsInputFromCart = exports.getTicketInput = exports.getOptionalSurchargesInput = exports.getBookingsInputFromCart = exports.getBookingInput = exports.cartItemIsUpsell = exports.cartItemIsTicket = exports.cartItemIsBooking = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _client = require("@apollo/client");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _react = require("react");
var _apollo = require("../apollo");
var _affiliate = require("../constants/affiliate");
var _storage = require("../constants/storage");
var _graphql = require("../generated/graphql");
var _auth = require("../utils/auth");
var _cart = require("../utils/cart");
var _fp = require("../utils/fp");
var _getApolloErrorType = _interopRequireDefault(require("../utils/getApolloErrorType"));
var _getStorageWithExpiry = _interopRequireDefault(require("../utils/getStorageWithExpiry"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const query = (0, _client.gql)`
    query GetCart($id: ID!) {
        cart(id: $id) {
            ...GetCart
        }
    }

    query CartStatus($cartId: ID!) {
        cart(id: $cartId) {
            id
            status
        }
    }

    mutation SetCart($input: UpdateCartInput!) {
        updateCart(input: $input) {
            cart {
                ...GetCart
            }
        }
    }

    fragment GetCart on Cart {
        id
        status
        availableUpsellOffers {
            id
            name
            unitPrice
            maxAmount
            description
        }
        order {
            orderItems {
                ...CartOrderItem
            }
        }
    }

    fragment CartOrderItem on OrderItem {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }

        totalPrice
        paymentPrice
        errors
        ...CartTicket
        ...CartBooking
        ...CartUpsell
    }

    fragment CartUpsell on UpsellPurchase {
        priceLines {
            label
            quantity
            totalPrice
            unitPrice
            category
        }
        offer {
            id
        }
        totalPrice
        paymentPrice
        amount
    }

    fragment CartBooking on Booking {
        status
        amountAdults
        amountPets
        amountChildren
        amountBabies
        amountYouths
        arrivalDate
        departureDate
        hasCancellationInsurance
        hasContentsInsurance
        handleDepositPayment
        deposit
        requiresApproval
        paymentPrice
        rentalUnit {
            id
        }
        special {
            id
        }
        optionalSurcharges {
            rentalUnitSurchargeId
            amount
        }
    }

    fragment CartTicket on Ticket {
        ticketStatus: status
        startDateTime
        activity {
            id
            slug
        }
        timeslot {
            ...ActivityPlannerTimeslot
        }

        rateLines {
            amount
            rateId
            totalPrice
            unitPrice
        }
    }
`;
const getTicketInput = ticket => {
  if (!ticket.timeslot || !ticket.rateLines) {
    return null;
  }
  return {
    timeslotId: ticket.timeslot.id,
    rateLines: ticket.rateLines.map(rateLine => ({
      amount: rateLine.amount,
      rateId: rateLine.rateId
    }))
  };
};
exports.getTicketInput = getTicketInput;
const getOptionalSurchargesInput = optionalSurcharges => optionalSurcharges.map(surcharge => ({
  amount: surcharge.amount,
  rentalUnitSurchargeId: surcharge.rentalUnitSurchargeId
}));
exports.getOptionalSurchargesInput = getOptionalSurchargesInput;
const getBookingInput = booking => ({
  amountAdults: booking.amountAdults,
  amountChildren: booking.amountChildren,
  amountBabies: booking.amountBabies,
  amountPets: booking.amountPets,
  amountYouths: booking.amountYouths,
  arrivalDate: booking.arrivalDate,
  departureDate: booking.departureDate,
  cancellationInsurance: booking.hasCancellationInsurance,
  contentsInsurance: booking.hasContentsInsurance,
  optionalSurcharges: getOptionalSurchargesInput(booking.optionalSurcharges),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  rentalUnitId: booking.rentalUnit?.id,
  specialId: booking.special?.id
});
exports.getBookingInput = getBookingInput;
const getUpsellInput = upsell => {
  if (!upsell.amount || !upsell.offer?.id) {
    return null;
  }
  return {
    amount: upsell.amount,
    upsellOfferId: upsell.offer?.id
  };
};
exports.getUpsellInput = getUpsellInput;
const cartItemIsBooking = item => item.__typename === 'Booking';
exports.cartItemIsBooking = cartItemIsBooking;
const cartItemIsTicket = item => item.__typename === 'Ticket';
exports.cartItemIsTicket = cartItemIsTicket;
const cartItemIsUpsell = item => item.__typename === 'UpsellPurchase';
exports.cartItemIsUpsell = cartItemIsUpsell;
const getTicketsInputFromCart = cart => {
  if (!cart || cart?.status === _graphql.CartStatusEnum.Closed) {
    return [];
  }
  return cart.order.orderItems.filter(cartItemIsTicket).map(getTicketInput).filter(_fp.truthy);
};
exports.getTicketsInputFromCart = getTicketsInputFromCart;
const getBookingsInputFromCart = cart => {
  if (!cart || cart?.status === _graphql.CartStatusEnum.Closed) {
    return [];
  }
  return cart.order.orderItems.filter(cartItemIsBooking).map(getBookingInput);
};
exports.getBookingsInputFromCart = getBookingsInputFromCart;
const getUpsellInputFromCart = cart => {
  if (!cart || cart?.status === _graphql.CartStatusEnum.Closed) {
    return [];
  }
  return cart.order.orderItems.filter(cartItemIsUpsell).map(getUpsellInput).filter(_fp.truthy);
};
exports.getUpsellInputFromCart = getUpsellInputFromCart;
const useCart = (document = _graphql.GetCartDocument, queryOptions) => {
  const cartQuery = (0, _apollo.useQuery)(document, _objectSpread({
    ssr: false,
    variables: {
      id: (0, _cart.getCartId)()
    },
    errorPolicy: 'ignore',
    skip: !(0, _cart.getCartId)()
  }, queryOptions));
  (0, _react.useEffect)(() => {
    const errorType = (0, _getApolloErrorType.default)(cartQuery.error);
    switch (errorType) {
      // if we get a server error we'll remove the cartId from localStorage
      case '500':
        (0, _cart.clearCartId)();
        break;
      case '401':
        (0, _auth.logout)();
        break;
      default:
        break;
    }
  }, [cartQuery.error]);
  const bookings = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsBooking) ?? [];
  const tickets = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsTicket) ?? [];
  const upsell = cartQuery.data?.cart?.order.orderItems.filter(cartItemIsUpsell) ?? [];
  return {
    tickets,
    bookings,
    upsell,
    cartQuery
  };
};
exports.useCart = useCart;
const useSetCart = (mutationDoc = _graphql.SetCartDocument, queryDoc = _graphql.GetCartDocument) => {
  const [mutate] = (0, _apollo.useMutation)(mutationDoc);
  const {
    0: loading,
    1: setLoading
  } = (0, _react.useState)(false);
  const client = (0, _client.useApolloClient)();
  const getCart = (0, _react.useCallback)(() => {
    const cartId = (0, _cart.getCartId)();
    if (!cartId) {
      return undefined;
    }
    return client.query({
      query: queryDoc,
      variables: {
        id: cartId
      },
      fetchPolicy: 'network-only'
    });
  }, [client, queryDoc]);
  const setCart = (0, _react.useCallback)(async opts => {
    setLoading(true);

    // first check if we're logged in, and refresh the token just in case
    if ((0, _getStorageWithExpiry.default)('local')?.getItem(_storage.ACCESS_TOKEN)) {
      const success = await (0, _auth.refresh)();
      if (!success) {
        (0, _auth.logout)();
      }
    }

    // then check if we already have a cartId we can use
    let reusedCartId = null;
    const cartQuery = await getCart();
    const currentCart = cartQuery?.data.cart;

    // only reuse the cart if it hasn't been closed yet
    reusedCartId = currentCart?.id && currentCart.status !== _graphql.CartStatusEnum.Closed ? currentCart.id : null;
    const currentTickets = getTicketsInputFromCart(currentCart);
    const currentBookings = getBookingsInputFromCart(currentCart);
    const tickets = opts.tickets ?? currentTickets;
    const bookings = opts.bookings ?? currentBookings;
    const affiliateHandle = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF);
    const affiliateReference = _jsCookie.default.get(_affiliate.TRAVELBASE_AFF_REFERENCE);
    const deviceId = localStorage.getItem(_storage.DEVICE_ID) ?? null;
    const result = await mutate(_objectSpread(_objectSpread({}, opts.options), {}, {
      variables: {
        input: {
          affiliateInfo: affiliateHandle ? {
            reference: affiliateReference,
            handle: affiliateHandle
          } : undefined,
          tickets,
          bookings,
          agreedToTerms: opts.agreedToTerms,
          cartId: reusedCartId,
          customerInfo: opts.customerInfo,
          paymentOptions: {
            sellDeviceId: deviceId
          }
        }
      }
    }));
    setLoading(false);
    if (result.data?.updateCart.cart?.id) {
      (0, _cart.setCartId)(result.data.updateCart.cart.id);
    }
    return result;
  }, [getCart, mutate]);
  const removeOrderItem = (0, _react.useCallback)(async (id, type) => {
    const cartQuery = await getCart();
    if (!cartQuery?.data.cart) {
      return;
    }
    await setCart({
      tickets: type === 'Ticket' ? getTicketsInputFromCart(cartQuery.data?.cart).filter(ticket => ticket.timeslotId !== id) ?? [] : undefined,
      bookings: type === 'Booking' ? getBookingsInputFromCart(cartQuery.data?.cart).filter(booking => booking.rentalUnitId !== id) ?? [] : undefined
    });
  }, [getCart, setCart]);
  return {
    setCart,
    removeOrderItem,
    loading
  };
};
exports.useSetCart = useSetCart;